@media only screen 
and (min-device-width : 308px) 
and (max-device-width : 480px) {
  .dyn-height {
    max-height:500px;
    overflow-y: scroll;
    position:fixed;
    bottom: 0;
    right: 0;
    display:flex;
    flex-direction:column;
  }

  .blogPost{
    font-family: 'Courier New', monospace;
    color: #c2b7b7d3;
    max-height:100vh;
    max-width:100vw;
    overflow-y: scroll;
    overflow-x: scroll;
    position:fixed;
    bottom: 0;
    right: 0;
    font-size: clamp(0.5rem, 8vw, 1rem);
  }

  .form {
    max-height:500px;
    overflow-y: scroll;
    position:fixed;
    bottom: 0;
    right: 30;
    display:flex;
    flex-direction:column;
  }

  .paragraphDiv{
    min-height: 110vh;
    max-width: 500px;
    margin-bottom: 100px;
  }

  .title{
    font-family: 'Courier New', monospace;
    color: #c2b7b7d3 !important;
    margin-top: 50px;
    justify-content: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    font-size: clamp(2rem, 10vw, 5rem);
    font-weight: bold;
  }

  .subtitle{
    font-family: 'Courier New', monospace;
    color: #c2b7b7d3;
    justify-content: center;
    text-align: center;
    display: flex;
    margin-top: 50px;
    flex-direction: column;
    font-size: clamp(1rem, 10vw, 2rem);
  }

  .card {
    margin-left: '2em';
    text-align: 'center';
    justify-content: 'center';
    margin-bottom: 45px;
  }

  .cardContainer{
      max-height:500px;
      overflow: scroll;
      display:flex;
      flex-direction:column;
  }
}

.mdeditor {
    max-height:520px;
    width: 100%;
    overflow-y: scroll;
    position:fixed;
    top: 80px;
    left: 0;
    display:flex;
    flex-direction:column;
}

.form {
  max-height:500px;
  overflow-y: scroll;
  position:fixed;
  bottom: 0;
  right: 0;
  display:flex;
  flex-direction:column;
}

.dyn-height {
    max-height:520px;
    overflow-y: scroll;
    position:fixed;
    bottom: 0;
    right: 0;
    display:flex;
    flex-direction:column;
  }

.cardContainer{
  display: 'flex'; 
  flex-direction: 'row';
}

.card {
  width: "18vw";
  margin-left: '2em';
  text-align: 'center';
  justify-content: 'center';
  display: 'flex'; 
  flex-direction: 'row';
}

.cardContainer{
  max-height:500px;
  overflow-y: scroll;
  position:center;
  display:flex;
  flex-direction:column;
  justify-content: 'center';
}

.table {
  max-height:500px;
  overflow: scroll;
}

.nav-link{
  font-family: 'Roboto', sans-serif;
  color: #ede2e1 !important;
}

.grey-text {
  color: #c2b7b7d3;
}

.title{
  font-family: 'Courier New', monospace;
  color: #c2b7b7d3 !important;
  margin-top: 50px;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  font-size: clamp(2rem, 10vw, 5rem);
}

.subtitle{
  font-family: 'Courier New', monospace;
  color: #c2b7b7d3;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  font-size: clamp(1rem, 10vw, 2rem);
}

.relativesub{
  font-family: 'Courier New', monospace;
  color: #c2b7b7d3;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  font-size: clamp(1rem, 10vw, 2rem);
  position:relative;
}

.paragraph{
  font-family: 'Courier New', monospace;
  color: #c2b7b7d3;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  font-size: clamp(0.5rem, 8vw, 1rem);
}

.blogPost{
  font-family: 'Courier New', monospace;
  color: #c2b7b7d3;
  max-height:80vh;
  overflow-y: scroll;
  position:fixed;
  top: 60px;
  bottom: 10vh;
  left: 0;
  width: 100vw;
  font-size: clamp(0.5rem, 8vw, 1rem);
}

.cardTitle{
  font-family: 'Courier New', monospace;
  color: #c2b7b7d3;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  font-size: clamp(1.2rem, 8vw, 1rem);
}


.paragraphDiv{
  max-width: 500px;
  margin: auto;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 10%;
  background-color: #3e405cd3;
  color: white;
  text-align: center;
  margin-top:auto; 
}

.socials {
  position: fixed;
  margin-right: 10px;
  margin-top: 10px;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: radial-gradient(ellipse at bottom, #1a3353 0%, #0c0d13 100%);
  overflow: hidden;
}